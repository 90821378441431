import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable, of } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import {
  IMediaConfig,
  MediaPurpose,
  MediaDocumentModel,
  MediaImageModel,
  MediaDocumentPurpose,
  ThumbNameEnum,
  PromoBannerMediaResModel,
  MediaVideoModel
} from '../models/media.model';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '../core/eros-api-config';
import { CreateMediaDocumentImageReqPayload } from '@eros-api/models';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/media`;
  }

  getMediaConfigs(): Observable<IMediaConfig> {
    const url = `${this.basePath}/configs`;

    return this.http.get<IMediaConfig>(url);
  }

  getMediaDocuments(ids: string[]): Observable<MediaDocumentModel[]> {
    const url = `${this.basePath}/documents`;

    const params = ids.reduce(
      (params, id) => params.append('ids[]', id),
      new HttpParams()
    );

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => MediaDocumentModel.fromJSON(rec))));
  }

  getMediaDocumentById(id: string): Observable<MediaDocumentModel> {
    const url = `${this.basePath}/document/${id}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => MediaDocumentModel.fromJSON(json)));
  }

  findMediaDocument(ids: string[]): Observable<MediaDocumentModel[]> {
    const url = `${this.basePath}/documents/_bulk`;

    return this.http
      .post<JsonArray>(url, { ids })
      .pipe(map((json) => json.map((rec) => MediaDocumentModel.fromJSON(rec))));
  }

  createMediaDocuments(
    files: File[],
    purpose: MediaDocumentPurpose,
    accountId?: number
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/documents`;

    let params = new HttpParams().set('purpose', purpose);

    if (accountId) {
      params = params.set('account_id', accountId);
    }

    const formData = this.createFormData(files);

    return this.http.post<{ id: string }>(url, formData, { params });
  }

  getMediaImages(
    ids: string[],
    thumbName: ThumbNameEnum
  ): Observable<MediaImageModel[]> {
    const url = `${this.basePath}/images`;

    let params = new HttpParams().set('thumb_name', thumbName);

    params = ids.reduce((params, id) => params.append('ids[]', id), params);

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => MediaImageModel.fromJSON(rec))));
  }

  getMediaImageById(
    id: string,
    thumbName: ThumbNameEnum
  ): Observable<MediaImageModel> {
    const url = `${this.basePath}/image/${id}`;

    const params = new HttpParams()
      .set('image_id', id)
      .set('thumb_name', thumbName);

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => MediaImageModel.fromJSON(json)));
  }

  adjustMediaImage(id: string): Observable<{}> {
    const url = `${this.basePath}/image/${id}/_adjust`;

    const params = new HttpParams().set('image_id', id);

    return this.http.post<{}>(url, { params });
  }

  findCommonMediaImages(ids: string[]): Observable<PromoBannerMediaResModel[]> {
    if (!ids?.length) {
      return of([]);
    }

    const url = `${this.basePath}/images/common/_bulk`;
    const body = { ids };

    return this.http.post<PromoBannerMediaResModel[]>(url, body);
  }

  findMediaImage(
    ids: string[],
    thumbName: ThumbNameEnum
  ): Observable<MediaImageModel[]> {
    if (!ids?.length) {
      return of([]);
    }

    const url = `${this.basePath}/images/_bulk`;
    const body = {
      ids,
      thumb_name: thumbName
    };

    return this.http
      .post<JsonArray>(url, body)
      .pipe(map((json) => json.map((rec) => MediaImageModel.fromJSON(rec))));
  }

  findMediaVideos(videoIds: string[]): Observable<MediaVideoModel[]> {
    if (!videoIds?.length) {
      return of([]);
    }

    const url = `${this.basePath}/videos/bulk`;
    const body = {
      videoIds
    };

    return this.http
      .post<JsonArray>(url, body)
      .pipe(map((json) => json.map((rec) => MediaVideoModel.fromJSON(rec))));
  }

  createMediaImage(
    files: File[],
    purpose: MediaPurpose,
    accountId?: number
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/images`;

    let params = new HttpParams().set('purpose', purpose);

    if (accountId) {
      params = params.set('account_id', accountId);
    }

    const formData = this.createFormData(files);

    return this.http.post<{ id: string }>(url, formData, { params });
  }

  createMediaDocumentImage(
    data: CreateMediaDocumentImageReqPayload
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/documents`;

    let params = new HttpParams().set('purpose', data.purpose);

    if (data.accountId) {
      params = params.set('account_id', data.accountId);
    }

    const formData = this.createFormData(data.files);

    return this.http.post<{ id: string }>(url, formData, { params });
  }

  private createFormData(files: File[]): FormData {
    return files.reduce((formData, file) => {
      formData.append('files[]', file);
      return formData;
    }, new FormData());
  }
}
