import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { JsonArray, JsonObject } from 'type-fest';
import { map, Observable } from 'rxjs';

import { AbstractErosApiConfig } from '../core/eros-api-config';

import {
  DiscountModel,
  DiscountReasonModel,
  DiscountConfigModelResPayload,
  DiscountCreateReqPayload,
  DiscountUpdateReqPayload,
  DiscountApplyReqPayload,
  DiscountCartApplyModel,
  DiscountCartApplyResPayload,
  DiscountCheckResPayload,
  DiscountStatsModel,
  DiscountUsageModel
} from '../models/discount.model';

/**
 * @link https://api.eros.com.sceon.am/discount/apidoc/
 * */
@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    this.basePath = `${config.getApiUrl()}/discount`;
  }

  getById(id: number): Observable<DiscountModel> {
    const url = `${this.basePath}/${id}`;

    return this.http
      .get<JsonObject>(url)
      .pipe(map((json) => DiscountModel.fromJSON(json)));
  }

  create(data: DiscountCreateReqPayload): Observable<{ id: number }> {
    const url = `${this.basePath}`;

    const body = DiscountModel.toJSONCreateModel(data);

    return this.http.post<{ id: number }>(url, body);
  }

  update(id: number, data: DiscountUpdateReqPayload): Observable<unknown> {
    const url = `${this.basePath}/${id}`;

    const body = DiscountModel.toJSONUpdateModel(data);

    return this.http.put<unknown>(url, body);
  }

  delete(id: number): Observable<unknown> {
    const url = `${this.basePath}/${id}`;

    return this.http.delete<unknown>(url, {});
  }

  /**
   * @desc Internal method
   * */
  apply(
    promoCode: string,
    data: DiscountApplyReqPayload[]
  ): Observable<DiscountCartApplyResPayload> {
    const url = `${this.basePath}/${promoCode}/_apply`;

    const body = data.map((item) => DiscountCartApplyModel.toJSON(item));

    return this.http
      .post<JsonObject>(url, body)
      .pipe(map((req) => DiscountCartApplyResPayload.fromJSON(req)));
  }

  check(data: DiscountCartApplyModel): Observable<DiscountCheckResPayload> {
    const url = `${this.basePath}/_check`;

    const body = DiscountCartApplyModel.toJSON(data);

    return this.http.post<DiscountCheckResPayload>(url, body);
  }

  getList(): Observable<DiscountModel[]> {
    const url = `${this.basePath}`;

    return this.http
      .get<JsonArray>(url)
      .pipe(map((json) => json.map((rec) => DiscountModel.fromJSON(rec))));
  }

  enable(id: number): Observable<unknown> {
    const url = `${this.basePath}/${id}/_enable`;

    return this.http.post<unknown>(url, {});
  }

  disable(id: number): Observable<unknown> {
    const url = `${this.basePath}/${id}/_disable`;

    return this.http.post<unknown>(url, {});
  }

  getConfig(): Observable<DiscountConfigModelResPayload> {
    const url = `${this.basePath}/configs`;

    return this.http.get<DiscountConfigModelResPayload>(url);
  }

  getReasons(): Observable<DiscountReasonModel[]> {
    const url = `${this.basePath}/reasons`;

    return this.http
      .get<JsonArray>(url)
      .pipe(
        map((json) => json.map((rec) => DiscountReasonModel.fromJSON(rec)))
      );
  }

  findDiscountStatsById(id: number): Observable<DiscountStatsModel[]> {
    const url = `${this.config.getApiUrl()}/report/discount/stats`;
    const params = new HttpParams().set('id', id.toString());

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => DiscountStatsModel.fromJSON(rec))));
  }

  findDiscountUsageById(id: number): Observable<DiscountUsageModel> {
    const url = `${this.config.getApiUrl()}/report/discount/usage`;
    const params = new HttpParams().set('id', id.toString());

    return this.http.get<DiscountUsageModel>(url, { params });
  }

  /**
   * @desc Internal method
   * */
  private migrate(id: number): Observable<{}> {
    const url = `${this.basePath}/${id}/_migrate`;

    return this.http.post<{}>(url, {});
  }
}
