import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExportToFileArgs } from '@eros-api/models/export-file.model';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient
  ) {}

  exportToFile(obj: ExportToFileArgs): Observable<HttpResponse<Blob>> {
    const {
      url,
      body = obj.body ? obj.body : { format: obj.format },
      filename = `${new Date().toLocaleString()}.${obj.format}`
    } = obj;

    return this.http
      .post(url, body, {
        withCredentials: false,
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        tap((httpResponse: HttpResponse<Blob>) => {
          const document = this.document;
          const { body } = httpResponse;
          const blob = new Blob([body], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');

          // Browsers that support HTML5 download attribute
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
      );
  }

  toCsv(arr: string[][], fileTitle: string) {
    const csvData = arr.reduce((csvString, row) => {
      row = row.map((cell) => this.escape(cell));
      csvString += row.join(',');
      csvString += '\r\n';
      return csvString;
    }, '');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileTitle}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  escape(value) {
    if (!['"', '\r', '\n', ','].some((e) => value.indexOf(e) !== -1)) {
      return value;
    }
    return '"' + value.replace(/"/g, '""') + '"';
  }
}
