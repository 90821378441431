import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { JsonArray } from 'type-fest';

import { AbstractErosApiConfig } from '../core';
import {
  CollectiveDashboardDocumentsModel,
  DeclinedAPOSModel,
  DeclinedAPOSReqBody,
  ProfileCreatePayload,
  ProfileModel,
  ProfileReqPayload
} from '../models/profile.model';
import { Configs } from '@eros-api/models/form-data.model';

/**
 * @link https://api.eros.com.sceon.am/escort-profile/apidoc/#api-Profile
 * */
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly basePath: string;

  constructor(private config: AbstractErosApiConfig, private http: HttpClient) {
    this.basePath = `${config.getApiUrl()}/escort-profile`;
  }

  getByAccountId(id: number): Observable<ProfileModel[]> {
    const url = `${this.basePath}`;

    const params = new HttpParams().set('account_id', id);

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((item) => ProfileModel.fromJSON(item))));
  }

  findById(id: number | string): Observable<ProfileModel> {
    const url = `${this.basePath}/${id}`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(map((json) => ProfileModel.fromJSON(json)));
  }

  getByIds(ids: string[]): Observable<ProfileModel[]> {
    const url = `${this.basePath}/_bulk`;

    return this.http
      .post<JsonArray>(url, { profile_ids: ids })
      .pipe(map((json) => json.map((item) => ProfileModel.fromJSON(item))));
  }

  copy(id: string, copyPhotos: boolean): Observable<{ profileId: string }> {
    const url = `${this.basePath}/${id}/_copy`;

    const body = { copy_photos: copyPhotos };

    return this.http
      .post<{ profile_id: string }>(url, body)
      .pipe(map((json) => ({ profileId: json.profile_id })));
  }

  create(data: ProfileCreatePayload): Observable<{ id: string }> {
    const url = this.basePath;

    const { isMulti, personId, accountId, isFast } = data;

    const body = ProfileModel.toJSON({
      isMulti,
      personId,
      accountId,
      isFast
    } as ProfileCreatePayload);

    return this.http.post<{ id: string }>(url, body);
  }

  delete(id: string | number): Observable<{}> {
    const url = `${this.basePath}/${id}`;

    return this.http.delete<{}>(url, {});
  }

  hide(id: string): Observable<{}> {
    const url = `${this.basePath}/${id}/_hide`;

    return this.http.post<{}>(url, {});
  }

  getList(data: ProfileReqPayload): Observable<ProfileModel[]> {
    const url = this.basePath;

    let params = new HttpParams()
      .set('account_id', data.accountId)
      .set('person_id', data.personId);

    if (data.showDeleted !== undefined) {
      params = params.set('show_deleted', Number(data.showDeleted));
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((item) => ProfileModel.fromJSON(item))));
  }

  updateOptions(id: string, autoApprove: boolean): Observable<{}> {
    const url = `${this.basePath}/${id}/options`;

    return this.http.put<{}>(url, { auto_approve: autoApprove });
  }

  reveal(id: string): Observable<{}> {
    const url = `${this.basePath}/${id}/_reveal`;

    return this.http.post<{}>(url, {});
  }

  revert(id: string | number): Observable<{}> {
    const url = `${this.basePath}/${id}/_revert`;

    return this.http.post<{}>(url, {});
  }

  switchToFast(id: string): Observable<{}> {
    const url = `${this.basePath}/${id}/_switch-to-fast`;

    return this.http.post<{}>(url, {});
  }

  getConfigs(): Observable<Configs> {
    return this.http.get<Configs>(`${this.basePath}/configs`);
  }

  // todo: define api return type
  createAuthenticated(id: string): Observable<any> {
    const url = `${this.basePath}/${id}/_authenticated`;

    return this.http.post<any>(url, {});
  }

  // todo: define api return type
  deleteAuthenticated(id: string): Observable<any> {
    const url = `${this.basePath}/${id}/_authenticated`;

    return this.http.delete<any>(url, {});
  }

  // todo: define api return type, and add body response
  availableNowOff(id: string): Observable<any> {
    const url = `${this.basePath}/_available-now-off`;

    return this.http.post<any>(url, { id });
  }

  // todo: define api return type, and add body response
  availableNow(id: string): Observable<any> {
    const url = `${this.basePath}/_available-now`;

    return this.http.post<any>(url, { id });
  }

  getAOPSReasonsByRevision(
    revisionId: string
  ): Observable<DeclinedAPOSModel[]> {
    const url = `${this.basePath}/aops-field?rev_id=${revisionId}`;

    return this.http
      .get<DeclinedAPOSModel[]>(url)
      .pipe(map((res) => res.map((item) => DeclinedAPOSModel.fromJson(item))));
  }

  createAOPSField(body: DeclinedAPOSReqBody): Observable<unknown> {
    const url = `${this.basePath}/aops-field`;

    return this.http.post(url, body);
  }

  deleteAOPSField(fieldId: string): Observable<unknown> {
    const url = `${this.basePath}/aops-field/${fieldId}`;

    return this.http.delete(url);
  }

  getCollectiveDashboardDocuments(): Observable<CollectiveDashboardDocumentsModel> {
    const url = `${this.basePath}/documents/pending-count`;

    return this.http.get<CollectiveDashboardDocumentsModel>(url);
  }
}
