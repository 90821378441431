import { FlagWordType } from '@eros-api/models';

export function sanitizeFlagWordValue(
  value: string,
  type: FlagWordType
): string {
  if (!value || !value.trim()) return null;

  switch (type) {
    case FlagWordType.BadPhoneNumber:
      return value.replace(/\D+/g, '').trim();
    case FlagWordType.BadLegalName:
    case FlagWordType.BadIdName:
    case FlagWordType.BadIdNumber:
    case FlagWordType.BadEmail:
    case FlagWordType.BadWebsite:
      return value.toLowerCase().trim();
    default:
      return value.trim();
  }
}
