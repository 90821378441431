import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';

import { AccountService } from '@eros-api/services';
import {
  AccountRoleEnum,
  AccountToken,
  OpsAccountModel
} from '@eros-api/models';

export const PERMISSIONS_KEY = 'permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private localStorage: Storage | null;

  public redirectUrl: string | null = null;

  get permissions(): string[] {
    const items = this.localStorage.getItem(PERMISSIONS_KEY) ?? '[]';
    return JSON.parse(items);
  }

  constructor(
    private accountService: AccountService,
    private jwtHelper: JwtHelperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.localStorage = this.document.defaultView?.localStorage || null;
  }

  hasAccessToken(): boolean {
    return (
      Boolean(this.getDecodedAccessToken()) && !this.isAccessTokenExpired()
    );
  }

  isAccessTokenExpired(): boolean {
    try {
      return this.jwtHelper.isTokenExpired(this.getAccessToken());
    } catch (e) {
      return true;
    }
  }

  getAccessToken(): string | null {
    return (this.jwtHelper.tokenGetter() as string) || null;
  }

  getDecodedAccessToken(): OpsAccountModel | null {
    try {
      return this.jwtHelper.decodeToken(this.getAccessToken()) || null;
    } catch (e) {
      return null;
    }
  }

  setAccessToken(token: string): void {
    this.localStorage?.setItem('access-token', token);
  }

  setPermissions(permissions: string[]): void {
    this.localStorage?.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  }

  removeAccessToken(): void {
    this.localStorage?.removeItem('access-token');
  }

  removePermissions(): void {
    this.localStorage?.removeItem(PERMISSIONS_KEY);
  }

  signIn(username: string, password: string): Observable<AccountToken> {
    return this.accountService.createAccessToken(username, password);
  }

  signOut(): void {
    this.removeAccessToken();
    this.removePermissions();
    this.redirectUrl = null;
  }

  hasPermission(permission: string): boolean {
    if (!this.getDecodedAccessToken) {
      return false;
    }

    return (
      this.getDecodedAccessToken().role === AccountRoleEnum.Admin ||
      !!this.permissions.find((perm: string) => {
        const regexp = this.makeRegExpFromPermission(permission);
        return perm.match(regexp) !== null;
      })
    );
  }

  makeRegExpFromPermission(permission: string): RegExp {
    permission = '^' + permission;
    permission = permission.replace(/\./g, '\\.');
    permission = permission.replace(/\*/g, '([^.]+)');
    permission = permission.replace(/#/g, '(.+)');
    permission += '$';
    return new RegExp(permission);
  }
}
