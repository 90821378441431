import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, map, of } from 'rxjs';
import { JsonObject, JsonArray } from 'type-fest';

import { AbstractErosApiConfig } from '../core';
import {
  ProfileRevisionModel,
  ProfileRevisionByIdResPayload
} from '../models/profile-revision.model';
import {
  ProfileMainCategory,
  ProfileModel,
  ProfileRevisionStatus
} from '../models/profile.model';

/**
 * @link https://api.eros.com.sceon.am/escort-profile/apidoc/#api-Revision
 * */
@Injectable({
  providedIn: 'root'
})
export class ProfileRevisionService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    this.basePath = `${config.getApiUrl()}/escort-profile`;
  }

  approve(id: string): Observable<{}> {
    const url = `${this.basePath}/rev/${id}/_approve`;

    return this.http.post<{}>(url, {});
  }

  getCategories(id: string): Observable<ProfileMainCategory[]> {
    const url = `${this.basePath}/rev/${id}/categories`;

    return this.http.get<ProfileMainCategory[]>(url, {});
  }

  decline(
    id: string,
    data: { reason: string[]; message: string }
  ): Observable<unknown> {
    const url = `${this.basePath}/rev/${id}/_decline`;

    return this.http.post<unknown>(url, data);
  }

  findLatestByProfileIds(
    profileIds: string[]
  ): Observable<ProfileRevisionModel[]> {
    const url = `${this.basePath}/revs/latest/_bulk`;

    return this.http
      .post<JsonArray>(url, { profile_ids: profileIds })
      .pipe(
        map((json) =>
          json.map((record) => ProfileRevisionModel.fromJSON(record))
        )
      );
  }

  findLatestByProfileId(
    profileId: string,
    numberLt?: number,
    status?: ProfileRevisionStatus,
    showDeleted?: boolean
  ): Observable<ProfileModel | null> {
    const url = `${this.basePath}/rev/latest`;

    let params = new HttpParams().set('profile_id', profileId);

    if (status) {
      params = params.set('status', status);
    }

    if (showDeleted !== undefined) {
      params = params.set('show_deleted', Number(showDeleted));
    }

    if (numberLt !== undefined) {
      params = params.set('number.lt', numberLt.toString());
    }

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => (json ? ProfileModel.fromJSON(json, true) : null)));
  }

  findRevIdsByProfileId(
    profileId: string
  ): Observable<ProfileRevisionByIdResPayload[]> {
    const url = `${this.basePath}/revs/ids`;

    const params = new HttpParams().set('profile_id', profileId);

    return this.http
      .get<ProfileRevisionByIdResPayload[]>(url, { params })
      .pipe(
        map((json) =>
          json.map((item) => ProfileRevisionByIdResPayload.fromJSON(item))
        )
      );
  }

  findLatestByProfileIdsHashMap(
    profileIds: string[]
  ): Observable<Record<string, ProfileRevisionModel>> {
    const url = `${this.basePath}/revs/latest`;

    const params = profileIds.reduce(
      (params, id) => params.append('profile_ids[]', id),
      new HttpParams()
    );

    return this.http
      .get<Array<{ _id: string; latest_revision: JsonObject }>>(url, {
        params
      })
      .pipe(
        map((json) =>
          json.reduce((acc, record) => {
            acc[record._id] = ProfileRevisionModel.fromJSON(
              record.latest_revision
            );
            return acc;
          }, {} as Record<string, ProfileRevisionModel>)
        )
      );
  }

  findByProfileIdAndRevIds(
    profileId: string,
    revIds: string[]
  ): Observable<ProfileRevisionModel[]> {
    const url = `${this.basePath}/revs`;

    let params = new HttpParams().set('profile_id', profileId);

    params = revIds.reduce((params, id) => params.append('ids[]', id), params);

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((revision) => ProfileRevisionModel.fromJSON(revision))
        )
      );
  }

  // todo: implement partial update
  patch(
    patch: Partial<ProfileRevisionModel>,
    isCopy?: boolean
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/rev`;

    for (const key in patch) {
      if (Array.isArray(patch[key]) && patch[key].length === 0) {
        patch[key] = null;
      }

      if (!patch[key]) {
        patch[key] = null;
      }
    }

    const body = ProfileRevisionModel.toJSON(patch);

    return Object.keys(patch).length
      ? this.http.patch<{ id: string }>(url, body)
      : of(null);
  }

  // todo: implement update
  update(
    isCopy: boolean,
    revision: Partial<ProfileRevisionModel>
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/rev`;

    const body = ProfileRevisionModel.toJSON(revision);

    return this.http.put<{ id: string }>(url, body);
  }

  getById(id: string, showDeleted?: boolean): Observable<ProfileModel> {
    const url = `${this.basePath}/rev/${id}`;

    let params = new HttpParams();

    if (showDeleted !== undefined) {
      params = params.set('show_deleted', Number(showDeleted));
    }

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => ProfileModel.fromJSON(json, true)));
  }

  revoke(id: string): Observable<{}> {
    const url = `${this.basePath}/${id}/_revoke`;

    return this.http.post<{}>(url, {});
  }

  findLatestByEmailOrPhoneNumber(
    query: string
  ): Observable<ProfileRevisionModel[]> {
    const url = `${this.basePath}/revs/latest/search`;

    const params = new HttpParams().set('query', query);

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((record) => ProfileRevisionModel.fromJSON(record))
        )
      );
  }

  getRevisionDeclineReasonTypeList(
    fieldId: string
  ): Observable<{ [key: string]: string }> {
    const url = `${this.basePath}/form-data/decline-reason-types?rev_id=${fieldId}`;

    return this.http.get<{ [key: string]: string }>(url);
  }
}
