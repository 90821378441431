import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { concatMap, map, Observable, of } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import { AbstractErosApiConfig } from '../core';
import {
  ProfilePhotoCreateReqPayload,
  ProfilePhotoCreateResPayload,
  ProfilePhotoFindManyByIdsPayload,
  ProfilePhotoModel
} from '../models/profile-photo.model';
import { MediaService } from '@eros-api/services/media.service';
import { ThumbNameEnum } from '@eros-api/models';

/**
 * @link https://api.eros.com.sceon.am/escort-profile/apidoc/#api-Photo
 * */
@Injectable({
  providedIn: 'root'
})
export class ProfilePhotoService {
  private readonly basePath: string;

  constructor(
    private config: AbstractErosApiConfig,
    private http: HttpClient,
    private mediaService: MediaService
  ) {
    this.basePath = `${config.getApiUrl()}/escort-profile`;
  }

  getById(id: string, showDeleted?: boolean): Observable<ProfilePhotoModel> {
    const url = `${this.basePath}/photo/${id}`;

    let params = new HttpParams();

    if (showDeleted !== undefined) {
      params = params.set('show_deleted', Number(showDeleted));
    }

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => ProfilePhotoModel.fromJSON(json)));
  }

  create(
    data: ProfilePhotoCreateReqPayload
  ): Observable<ProfilePhotoCreateResPayload> {
    const url = `${this.basePath}/photos`;

    const body: any = {
      account_id: data.accountId,
      person_id: data.personId
    };

    if (data.imageId) {
      body.image_id = data.imageId;
    }
    if (data.imageIds) {
      body.image_ids = data.imageIds;
    }

    return this.http.post<any[]>(url, body).pipe(
      map((json) =>
        json.map((rec) => ({
          photoId: rec.photo_id,
          imageId: rec.image_id,
          number: rec.number
        }))
      )
    );
  }

  delete(ids: string[]): Observable<any> {
    const url = `${this.basePath}/photos`;

    const params = appendParams(ids, 'photo_ids[]', new HttpParams());

    return this.http.delete<any>(url, { params });
  }

  update(id: string, personId: string): Observable<{}> {
    const url = `${this.basePath}/photo/${id}`;

    return this.http.put<{}>(url, { person_id: personId });
  }

  approve(ids: string[]): Observable<{}> {
    const url = `${this.basePath}/photos/_approve`;

    const body = ids.map((id) => ({ id }));

    return this.http.post<{}>(url, body);
  }

  decline(ids: string[]): Observable<unknown> {
    const url = `${this.basePath}/photos/_decline`;

    const body = ids.map((id) => ({ id }));

    return this.http.post<unknown>(url, body);
  }

  stamp(id: string, stampedBy: number): Observable<{}> {
    const url = `${this.basePath}/photo/${id}/_stamp`;

    const body = { stamped_by: stampedBy };

    return this.http.post<{}>(url, body);
  }

  getMainPhotoByProfileId(
    profileId: string,
    showDeleted?: boolean
  ): Observable<ProfilePhotoModel> {
    const url = `${this.basePath}/${profileId}/main-photo`;

    let params = new HttpParams();

    if (showDeleted) {
      params = params.set('show_deleted', Number(showDeleted));
    }

    return this.http
      .get<JsonObject>(url, { params })
      .pipe(map((json) => ProfilePhotoModel.fromJSON(json)));
  }

  findManyByIds(
    data: ProfilePhotoFindManyByIdsPayload
  ): Observable<ProfilePhotoModel[]> {
    if (!data.photoIds?.length) {
      return of([]);
    }

    const url = `${this.basePath}/photos/_bulk`;

    const body: any = {
      account_id: data.accountId,
      photo_ids: data.photoIds
    };

    if (data.personId) {
      body.person_id = data.personId;
    }
    if (data.showDeleted !== undefined) {
      body.show_deleted = Number(data.showDeleted);
    }

    return this.http.post<JsonArray>(url, body).pipe(
      concatMap((json) => {
        const photos = json.map((item) => ProfilePhotoModel.fromJSON(item));
        const imageIds = photos.map((p) => p.imageId);
        return this.mediaService
          .findMediaImage(imageIds, data.thumbName || ThumbNameEnum.Thumb265)
          .pipe(
            map((medias) =>
              photos.map((photo) => ({
                ...photo,
                media: medias.find((m) => m.imageId === photo.imageId)
              }))
            )
          );
      })
    );
  }

  findManyByProfileId(
    profileId: string,
    showDeleted?: boolean
  ): Observable<ProfilePhotoModel[]> {
    const url = `${this.basePath}/${profileId}/photos`;

    let params = new HttpParams();

    if (showDeleted) {
      params = params.set('show_deleted', Number(showDeleted));
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => ProfilePhotoModel.fromJSON(rec))));
  }

  markAOPS(id: string, markedAOPSBy: number | null): Observable<{}> {
    const url = `${this.basePath}/photo/${id}/_mark-aops`;

    const body = { marked_aops_by: markedAOPSBy };

    return this.http.post<{}>(url, body);
  }
}

// todo: move function into utilities
function appendParams(ids: string[], paramsName: string, params: HttpParams) {
  return ids.reduce((acc, id) => {
    return acc.append(paramsName, id);
  }, params);
}
