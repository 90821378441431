import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { JsonObject, JsonArray } from 'type-fest';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '../core';
import {
  ProfileDocumentModel,
  ICreateManyDocumentsResPayload,
  ChangeDocumentStatusReqPayload
  // ISumsubCallbackResponse,
  // ISumsubGetCountriesResponse
} from '@eros-api/models';
import { environment } from '@env/environment';

/**
 * @link https://api.eros.com.sceon.am/escort-profile/apidoc/#api-Document
 * */
@Injectable({
  providedIn: 'root'
})
export class ProfileDocumentService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/escort-profile`;
  }

  getById(id: string): Observable<ProfileDocumentModel> {
    const url = `${this.basePath}/document/${id}`;

    return this.http
      .get<JsonObject>(url)
      .pipe(map((json) => ProfileDocumentModel.fromJson(json)));
  }

  create(document: Partial<ProfileDocumentModel>): Observable<{ id: string }> {
    const url = `${this.basePath}/documents`;

    const params = new HttpParams().set(
      'account_id',
      document.accountId.toString()
    );

    const body = ProfileDocumentModel.toJson(document);

    return this.http.post<{ id: string }>(url, body, { params });
  }

  update(document: Partial<ProfileDocumentModel>): Observable<unknown> {
    const url = `${this.basePath}/document/${document.id}`;

    const body = ProfileDocumentModel.toJson(document);

    return this.http.put<unknown>(url, body);
  }

  delete(id: string): Observable<unknown> {
    const url = `${this.basePath}/document/${id}`;

    return this.http.delete<unknown>(url);
  }

  findDuplicateAccounts(
    accountId: number,
    idNumber: string
  ): Observable<{ accounts: number[] }> {
    const url = `${this.basePath}/documents/pending`;

    const params = new HttpParams()
      .set('account_id', accountId)
      .set('id_number', idNumber);

    return this.http.get<{ accounts: number[] }>(url, { params });
  }

  // todo: add { person: ProfilePersonModel } field to ProfileDocumentModel or extend it
  findPending(): Observable<ProfileDocumentModel[]> {
    const url = `${this.basePath}/documents/pending`;

    return this.http
      .get<JsonArray>(url)
      .pipe(
        map((json) => json.map((item) => ProfileDocumentModel.fromJson(item)))
      );
  }

  findByAccountId(
    accountId: number,
    personId?: string
  ): Observable<ProfileDocumentModel[]> {
    const url = `${this.basePath}/documents`;

    let params = new HttpParams().set('account_id', accountId);

    if (typeof personId !== 'undefined') {
      params = params.set('person_id', personId);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) => json.map((item) => ProfileDocumentModel.fromJson(item)))
      );
  }

  createMany(
    documents: Partial<ProfileDocumentModel>[]
  ): Observable<ICreateManyDocumentsResPayload> {
    const url = `${this.basePath}/documents/_bulk`;

    const docs = documents.map((doc) => ProfileDocumentModel.toJson(doc));

    return this.http.post<ICreateManyDocumentsResPayload>(url, { docs });
  }

  getIDDocumentSumSubUrl(applicantId: string): string {
    return environment.sumSubUrl.replace('{{personId}}', applicantId);
  }

  changeStatus(
    payload: ChangeDocumentStatusReqPayload
  ): Observable<{ modified: 0 | 1 }> {
    const url = `${this.basePath}/documents/bulk-process`;

    const body = {
      account_id: payload.accountId,
      status: payload.status,
      document_ids: payload.documentIds
    };

    return this.http.patch<{ modified: 0 | 1 }>(url, body);
  }

  // SumSub
  // sumsubCreateAccessToken(personId: string): Observable<string> {
  //   const url = `${this.basePath}/documents/sumsub/create-access-token`;
  //   const params = new HttpParams().set('person_id', personId);
  //
  //   return this.http.get<string>(url, { params });
  // }
  //
  // sumsubGetCountries(): Observable<ISumsubCountriesResponse> {
  //   const url = `${this.basePath}/documents/sumsub/countries`;
  //
  //   return this.http.get<ISumsubCountriesResponse>(url);
  // }
  //
  // sumsubCallback(): Observable<ISumsubCallbackResponse> {
  //   const url = `${this.basePath}/documents/sumsub/callback`;
  //
  //   return this.http.get<ISumsubCallbackResponse>(url);
  // }
}
