export class IpsModel {
  took: number;
  timedOut: boolean;
  shards: IShards;
  hits: IHits;

  static fromJSON(json: any): IpsModel {
    return {
      took: json.took,
      timedOut: json.timed_out,
      shards: json._shards,
      hits: {
        total: json.hits.total,
        maxScore: json.hits.max_score,
        hits: json.hits.hits.map((hit) => ({
          index: hit._index,
          type: hit._type,
          id: hit._id,
          score: hit._score,
          source: {
            type: hit._source.type,
            accountId: hit._source.account_id,
            ip: hit._source.ip,
            date: new Date(hit._source.date)
          }
        }))
      }
    };
  }
}

export interface IShards {
  total: number;
  successful: number;
  failed: number;
}

export interface IHits {
  total: number;
  maxScore?: any;
  hits: IIPsHit[];
}

export interface ISource {
  type: string;
  accountId: number;
  ip: string;
  date: Date;
}

export interface IIPsHit {
  index: string;
  type: string;
  id: string;
  score: number;
  source: ISource;
}

export interface IpsResult {
  type: IpsResultType;
  emails?: string[];
  phone_numbers?: string[];
  websites?: string[];
  account_id: number;
  reference_id: number;
  contact_name: string;
  email: string;
  first_name: string;
  highlight: { security_answer: string };
  last_name: string;
  date_processed: string;
  middle_name: string;
  phone_number: string;
  sales_account_id: number | null;
  secret_pin: string;
  security_answer: string;
  security_question: string;
  username: string;
  showname: string;
  _score: number;
  action_view: string;
}

export enum IpsResultType {
  Account = 'account',
  Ad = 'ad',
  ipTracking = 'ip-tracking',
  RevisionHistory = 'revision-history'
}

export class IpsGroup {
  constructor(
    public name: IpsResult['type'],
    public result: IpsResult[] = []
  ) {}
}
