import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable, of } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '@eros-api/core/index';
import {
  DailySolved,
  ITicketConfig,
  TicketBulkResPayload,
  TicketCountByProfileIdReqPayload,
  TicketEscalateReqPayload,
  TicketModel,
  TicketResolveReqPayload,
  TicketCountByQueue,
  CollectiveTicketsModel
} from '../models/ticket.model';
import { AuthService } from '@app/libs/auth/data-access';
import { TicketQueue } from '@app/libs/ticket/features/tickets/state/utils';

/**
 * @link https://api.eros.com.sceon.am/ticket/apidoc/#api-Ticket
 * */
@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private readonly basePath: string;

  readonly ticketTaken$ = of({ payload: {} });
  readonly ticketCreated$ = of({ payload: {} });
  readonly ticketClosed$ = of({ payload: {} });
  readonly ticketQueueChanged$ = of({ payload: {} });
  readonly ticketReassigned$ = of({ payload: {} });
  readonly ticketUntaken$ = of({ payload: {} });

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private authService: AuthService,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/ticket`;
  }

  getConfig(): Observable<ITicketConfig> {
    const url = `${this.basePath}/configs`;

    return this.http.get<ITicketConfig>(url, {});
  }

  getList(queue?: string): Observable<TicketModel[]> {
    const url = `${this.basePath}`;

    let params = new HttpParams();

    if (queue) {
      params = params.set('queue', queue);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => TicketModel.fromJSON(rec))));
  }

  assign(ticketId: string, accountId: number): Observable<unknown> {
    const url = `${this.basePath}/${ticketId}/_assign`;
    const body = {
      account_id: accountId
    };

    return this.http.post<unknown>(url, body);
  }

  findByProfileIds(profileIds: string[]): Observable<TicketBulkResPayload[]> {
    const url = `${this.basePath}/_bulk`;
    const body = {
      profile_ids: profileIds
    };

    return this.http.post<any[]>(url, body).pipe(
      map((json) =>
        json.map(
          (rec) =>
            ({
              profileId: rec.profile_id,
              priority: rec.priority
            } as TicketBulkResPayload)
        )
      )
    );
  }

  close(ticketId: string): Observable<{}> {
    const url = `${this.basePath}/${ticketId}/_close`;

    return this.http.post<{}>(url, {});
  }

  getCountByFilter(data: TicketCountByProfileIdReqPayload): Observable<number> {
    const url = `${this.basePath}/count-by-profile-id`;

    const body = {
      account_id: data.accountId,
      profile_id: data.profileId,
      priorities: data.priorities
    };

    return this.http.post<number>(url, body);
  }

  escalate(
    ticketId: string,
    data: TicketEscalateReqPayload
  ): Observable<unknown> {
    const url = `${this.basePath}/${ticketId}/_escalate`;

    const body: Record<string, unknown> = {
      type: data.type
    };

    if (data.note) {
      body['note'] = data.note;
    }

    return this.http.post<unknown>(url, body);
  }

  getByRevId(revId: string): Observable<TicketModel> {
    const url = `${this.basePath}/by/rev-id/${revId}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => TicketModel.fromJSON(json)));
  }

  getById(ticketId: string): Observable<TicketModel> {
    const url = `${this.basePath}/${ticketId}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => TicketModel.fromJSON(json)));
  }

  resolve(
    ticketId: string,
    data: TicketResolveReqPayload
  ): Observable<unknown> {
    const url = `${this.basePath}/${ticketId}/_resolve`;

    const body: Record<string, unknown> = {
      result: data.result
    };

    if (data.note) {
      body['note'] = data.note;
    }

    return this.http.post<unknown>(url, body);
  }

  take(ticketId: string): Observable<unknown> {
    const url = `${this.basePath}/${ticketId}/_take`;

    return this.http.post<unknown>(url, {});
  }

  unTake(ticketId: string): Observable<unknown> {
    const url = `${this.basePath}/${ticketId}/_untake`;

    return this.http.post<unknown>(url, {});
  }

  getTicketCountByQueue(
    queues?: TicketQueue[]
  ): Observable<TicketCountByQueue> {
    const url = `${this.basePath}/by/queue-count`;

    const params = new HttpParams({
      fromString: queues?.map((queue) => `queue[]=${queue}`).join('&')
    });

    return this.http.get<TicketCountByQueue>(url, { params });
  }

  getTodaySolved(performedBy?: number): Observable<DailySolved> {
    const url = `${this.config.getApiUrl()}/report/ticket/by-staff/solved`;

    const tz = '-04:00';

    const params = new HttpParams()
      .set('tz', tz)
      .set(
        'performed_by',
        performedBy
          ? performedBy.toString()
          : this.authService.getDecodedAccessToken().id.toString()
      );

    return this.http.get<DailySolved>(url, { params });
  }

  getTicketsCountByPriority(): Observable<{
    response: CollectiveTicketsModel;
  }> {
    const url = `${this.basePath}/count-by-priority`;

    return this.http.get<{ response: CollectiveTicketsModel }>(url, {});
  }
}
