import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '../core/eros-api-config';
import {
  ActivityModel,
  ActivityCreateReqPayload,
  ActivityListReqPayload,
  ActivityListFlaggedHighlightReqPayload,
  ActivityAlertModel,
  ActivityAlertCreateReqPayload,
  ActivityAlertUpdateReqPayload,
  ActivityAlertUpdateResPayload,
  ActivityAlertDeleteResPayload,
  ActivityAlertReorderResPayload,
  IActivityConfig
} from '../models/activity.model';

/**
 * @link https://api.eros.com.sceon.am/activity/apidoc/
 * */
@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/activity`;
  }

  getActivityById(activityId: string): Observable<ActivityModel> {
    const url = `${this.basePath}/${activityId}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => ActivityModel.fromJSON(json)));
  }

  getActivityList(data: ActivityListReqPayload): Observable<ActivityModel[]> {
    const url = this.basePath;

    let params = new HttpParams().set('account_id', data.accountId);

    if (data.limit) {
      params = params.set('_limit', data.limit);
    }

    // TODO: make manual test
    if (data.type) {
      params = data.type.reduce(
        (params, type) => params.append('type', type as unknown as string),
        params
      );
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => ActivityModel.fromJSON(rec))));
  }

  createActivity(
    accountId: number,
    data: ActivityCreateReqPayload
  ): Observable<{ id: string }> {
    const url = `${this.basePath}`;
    const body: Record<string, any> = {
      text: data.text,
      account_id: accountId,
      type: data.type
    };

    return this.http.post<{ id: string }>(url, body);
  }

  updateActivity(activityId: string, text: string): Observable<{}> {
    const url = `${this.basePath}/${activityId}`;
    const body = { text };

    return this.http.put<{}>(url, body);
  }

  amendActivity(activityId: string, text: string): Observable<{}> {
    const url = `${this.basePath}/${activityId}/_amend`;
    const body = { text };

    return this.http.post<{}>(url, body);
  }

  getActivityListFlaggedHighlight(
    data: ActivityListFlaggedHighlightReqPayload
  ): Observable<Record<string, any>> {
    const url = `${this.basePath}/flagged-highlight`;

    let params = new HttpParams()
      .set('date_from', data.dateFrom)
      .set('date_to', data.dateTo);

    if (data.tz) {
      params = params.set('account_id', data.tz);
    }

    return this.http.get<Record<string, any>>(url, { params });
  }

  getActivityAlertById(alertId: string): Observable<ActivityAlertModel> {
    const url = `${this.basePath}/alert/${alertId}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => ActivityAlertModel.fromJSON(json)));
  }

  getActivityAlertList(accountId: number): Observable<ActivityAlertModel[]> {
    const url = `${this.basePath}/alerts`;

    const params = new HttpParams().set('account_id', accountId);

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => ActivityAlertModel.fromJSON(rec))));
  }

  createActivityAlert(
    data: ActivityAlertCreateReqPayload
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/alerts`;

    const body: JsonObject = {
      text: data.text,
      type: data.type,
      account_id: data.accountId
    };

    if (data.personId) {
      body['person_id'] = data.personId;
    }

    return this.http.post<{ id: string }>(url, body);
  }

  updateActivityAlert(
    alertId: string,
    data: ActivityAlertUpdateReqPayload
  ): Observable<ActivityAlertUpdateResPayload> {
    const url = `${this.basePath}/alert/${alertId}`;

    const body: Record<string, any> = {
      text: data.text
    };

    if (data.personId) {
      body['person_id'] = data.personId;
    }

    if (data.type) {
      body['type'] = data.type;
    }

    return this.http.put<ActivityAlertUpdateResPayload>(url, body);
  }

  deleteActivityAlert(
    alertId: string
  ): Observable<ActivityAlertDeleteResPayload> {
    const url = `${this.basePath}/alert/${alertId}`;

    return this.http.delete<ActivityAlertDeleteResPayload>(url, {});
  }

  reorderActivityAlert(
    ids: string[]
  ): Observable<ActivityAlertReorderResPayload> {
    const url = `${this.basePath}/alerts/_reorder`;
    const body = ids.reverse().map((id) => ({ id }));

    return this.http.post<ActivityAlertReorderResPayload>(url, body);
  }

  getActivityConfigs(): Observable<IActivityConfig> {
    const url = `${this.basePath}/configs`;

    return this.http.get<IActivityConfig>(url, {});
  }

  createConversation(
    escalationId: string,
    text: string
  ): Observable<{ _id: string }> {
    const url = `${this.basePath}/escalation/${escalationId}/conversation`;
    const body = { text };

    return this.http.post<{ _id: string }>(url, body);
  }

  updateConversation(
    escalationId: string,
    conversationId: string,
    text: string
  ): Observable<{}> {
    const url = `${this.basePath}/escalation/${escalationId}/conversation/${conversationId}`;
    const body = { text };

    return this.http.put<{}>(url, body);
  }
}
