import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sanitizeFlagWordValue } from '@app/libs/account/utils/sanitize-flag-word-value';
import { IpsModel } from '@eros-api/models/ips.model';
import { AccountService } from '@eros-api/services/account.service';
import { forkJoin, map, Observable, of } from 'rxjs';

import { AbstractErosApiConfig } from '@eros-api/core';
import {
  AccountModel,
  EgTermModel,
  EgTermUpdateReqPayload,
  FlagWordCreateReqPayload,
  FlagWordsModel,
  FlagWordType,
  IEgTermResModel,
  IFindFlagWordsByTypeValueReqModel,
  IFindFlagWordsByTypeValuesReqModel,
  IFlagWordResModel,
  ISendNotificationMessageReqModel,
  ITemplateResModel,
  MarkAsBadFlagWord,
  TemplateModel
} from '@eros-api/models';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private readonly basePath: string;
  private accountList: AccountModel[] = [];

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private accountService: AccountService,
    private http: HttpClient
  ) {
    this.basePath = `${config.getApiUrl()}/fraud`;
  }

  getFlagWords(): Observable<FlagWordsModel[]> {
    const url = `${this.basePath}/flagwords`;

    return this.http
      .get<IFlagWordResModel[]>(url)
      .pipe(
        map((json) => json.map((record) => FlagWordsModel.fromJSON(record)))
      );
  }

  deleteFlagWord(id: string): Observable<{ found: boolean }> {
    const url = `${this.basePath}/flagword/${id}`;

    return this.http.delete<{ found: boolean }>(url, {});
  }

  updateFlagWord(flagWord: FlagWordsModel): Observable<null> {
    const url = `${this.basePath}/flagword/${flagWord.id}`;

    const body = {
      type: flagWord.type,
      value: flagWord.value
    };

    return this.http.put<null>(url, body);
  }

  createFlagWord(
    flagWord: FlagWordCreateReqPayload
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/flagwords`;

    const body = {
      type: flagWord.type,
      value: flagWord.value
    };

    return this.http.post<{ id: string }>(url, body);
  }

  markAsBadAccountField(body: MarkAsBadFlagWord): Observable<{ id: string }> {
    const url = `${this.basePath}/flagwords`;

    return this.http.post<{ id: string }>(url, body);
  }

  getTemplates(): Observable<TemplateModel[]> {
    const url = `${this.config.getApiUrl()}/notification/templates`;

    return this.http
      .get<ITemplateResModel[]>(url)
      .pipe(
        map((json) => json.map((record) => TemplateModel.fromJSON(record)))
      );
  }

  deleteTemplate(id: string): Observable<{ id: string }> {
    const url = `${this.config.getApiUrl()}/notification/template/${id}`;

    return this.http.delete<{ id: string }>(url, {});
  }

  updateTemplate(template: TemplateModel): Observable<TemplateModel> {
    const url = `${this.config.getApiUrl()}/notification/template/${
      template.id
    }`;

    const body = {
      type: template.type,
      availability: template.availability,
      title: template.title,
      body: template.body,
      subject: template.subject ? template.subject : null
    };

    return this.http.put<TemplateModel>(url, body);
  }

  createTemplate(template: TemplateModel): Observable<{ id: string }> {
    const url = `${this.config.getApiUrl()}/notification/templates`;

    const body = {
      ...template,
      title: template.title,
      body: template.body,
      subject: template.subject ? template.subject : null
    };

    return this.http.post<TemplateModel>(url, body);
  }

  sendMessage(
    reqBody: ISendNotificationMessageReqModel
  ): Observable<{ id: string }> {
    const url = `${this.config.getApiUrl()}/notification/messages`;
    return this.http.post<TemplateModel>(url, reqBody);
  }

  getEgTerms(): Observable<EgTermModel[]> {
    const url = `${this.config.getApiUrl()}/fraud/eg-terms`;

    return this.http
      .get<IEgTermResModel[]>(url)
      .pipe(map((json) => json.map((record) => EgTermModel.fromJSON(record))));
  }

  deleteEgTerm(id: string): Observable<{ found: boolean }> {
    const url = `${this.config.getApiUrl()}/fraud/eg-term/${id}`;

    return this.http.delete<{ found: boolean }>(url, {});
  }

  updateEgTerm(egTerm: EgTermUpdateReqPayload): Observable<EgTermModel> {
    const url = `${this.config.getApiUrl()}/fraud/eg-term/${egTerm.id}`;

    const body = EgTermModel.toJSONUpdateModel(egTerm);

    return this.http.put<EgTermModel>(url, body);
  }

  createEgTerm(egTerm: EgTermModel): Observable<{ id: string }> {
    const url = `${this.config.getApiUrl()}/fraud/eg-terms`;

    const body = EgTermModel.toJSONCreateModel(egTerm);

    return this.http.post<EgTermModel>(url, body);
  }

  getIPsByAccountId(accountId: number): Observable<IpsModel> {
    const url = `${this.config.getApiUrl()}/fraud/account/${accountId}/ips`;

    return this.http
      .get<IpsModel>(url)
      .pipe(map((data) => IpsModel.fromJSON(data)));
  }

  findByTypeValue = (
    data: IFindFlagWordsByTypeValueReqModel[]
  ): Observable<FlagWordsModel[]> => {
    const grouped = data
      .filter(({ type, value }) => {
        return sanitizeFlagWordValue(value, type as FlagWordType);
      })
      .reduce((acc, { type, value }) => {
        if (!Array.isArray(acc[type])) {
          acc[type] = [];
        }
        acc[type].push(value);
        return acc;
      }, {});

    const body = Object.entries(grouped).map(([key, value]) => ({
      type: key,
      values: value
    }));

    if (body.length === 0) {
      return of([]);
    }

    const url = `${this.basePath}/flagwords/bad-contacts/_bulk`;

    return forkJoin([
      this.http.post<IFlagWordResModel[]>(url, body),
      this.accountList.length
        ? of(this.accountList)
        : this.accountService.getList()
    ]).pipe(map(this.configureFlagWordsModel));
  };

  findByTypeValues = (
    body: IFindFlagWordsByTypeValuesReqModel[]
  ): Observable<FlagWordsModel[]> => {
    if (body.length === 0) {
      return of([]);
    }

    const url = `${this.basePath}/flagwords/bad-contacts/_bulk`;

    return forkJoin([
      this.http.post<IFlagWordResModel[]>(url, body),
      this.accountList.length
        ? of(this.accountList)
        : this.accountService.getList()
    ]).pipe(map(this.configureFlagWordsModel));
  };

  configureFlagWordsModel = ([json, accounts]) => {
    this.accountList = accounts;
    return json.map((item) => {
      item.created_by_obj =
        accounts.find((acc) => acc.id === item.created_by) ||
        AccountModel.fromJSON({ id: 0, username: 'Mr. Unknown' });
      item.created_by = item.created_by_obj.id;
      if (item.modified_by) {
        item.modified_by_obj =
          accounts.find((acc) => acc.id === item.modified_by) ||
          AccountModel.fromJSON({ id: 0, username: 'Mr. Unknown' });
      }
      return FlagWordsModel.fromJSON(item);
    });
  };
}
