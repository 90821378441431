import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '../core/eros-api-config';
import {
  AggregatorCategoryModel,
  AggregatorGeographyFullModel,
  AggregatorGeographyModel,
  AggregatorGeographyNodeModel,
  AggregatorLocationPresentationCategoryCountReqPayload,
  AggregatorLocationPresentationCategoryCountResPayload,
  AggregatorLocationPresentationCategoryModel,
  AggregatorPresentationCategoryModel,
  AggregatorProfileBacklinkModel,
  AggregatorBulkOnlineProfileResPayload,
  AggregatorProfileFillFeaturedReqPayload,
  AggregatorProfileFillPaidSpotReqPayload,
  AggregatorProfileModel,
  AggregatorOnlineProfileCountResPayload,
  AggregatorProfileReqPayload,
  AggregatorSiteModel,
  AggregatorTrendingMetaModel,
  AggregatorV2SiteModel,
  IAggregatorGeography,
  AggregatorOnlineProfileResPayload,
  AggregatorOnlineProfileReqPayload,
  AggregatorPagingProfileReqPayload,
  AggregatorPagingProfileResPayload,
  AggregatorProfilePhotoModel,
  AggregatorProfileSitemapReqPayload,
  AggregatorProfileSitemapResPayload,
  AggregatorProfileTrendingPagingReqPayload,
  AggregatorProfileTrendingPagingResPayload,
  AggregatorProfileWhatsNewDateResPayload,
  AggregatorProfileWhatsNewForceResPayload,
  AggregatorProfileWhatsNewPagingReqPayload,
  AggregatorProfileWhatsNewPagingResPayload,
  IAggregatorProfileList,
  AggregatorProfileListModel,
  AggregatorSearchProfileReqPayload,
  AggregatorProfileTrendingReqPayload,
  AggregatorProfileWhatsNewListReqPayload
} from '../models/aggregator.model';

/**
 * @link https://api.eros.com.sceon.am/ff-aggregator/apidoc/
 * */
@Injectable({
  providedIn: 'root'
})
export class AggregatorService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/ff-aggregator`;
  }

  getCategories(
    locationId: number,
    presentationCategoryId?: number
  ): Observable<AggregatorCategoryModel[]> {
    const url = `${this.basePath}/categories`;

    let params = new HttpParams().set('location_id', locationId);

    if (presentationCategoryId) {
      params = params.set('presentation_category_id', presentationCategoryId);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) => json.map((rec) => AggregatorCategoryModel.fromJSON(rec)))
      );
  }

  getSubCategories(locationId: number): Observable<AggregatorCategoryModel[]> {
    const url = `${this.basePath}/categories/sub`;

    const params = new HttpParams().set('location_id', locationId);

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) => json.map((rec) => AggregatorCategoryModel.fromJSON(rec)))
      );
  }

  getGeographyCountries(): Observable<IAggregatorGeography[]> {
    const url = `${this.basePath}/geography/countries/`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) => json.map((rec) => AggregatorGeographyModel.fromJSON(rec)))
      );
  }

  getGeographyCountryById(countryId: number): Observable<IAggregatorGeography> {
    const url = `${this.basePath}/geography/country/${countryId}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => AggregatorGeographyModel.fromJSON(json)));
  }

  getGeographyFullCountries(
    mainPresentationCategoryId?: number
  ): Observable<AggregatorGeographyFullModel[]> {
    const url = `${this.basePath}/geography/countries/full`;

    let params = new HttpParams();

    if (mainPresentationCategoryId) {
      params = params.set(
        'main_presentation_category_id',
        mainPresentationCategoryId
      );
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorGeographyFullModel.fromJSON(rec))
        )
      );
  }

  getGeographyNodeList(
    nodeId: number,
    presentationCategoryId: number
  ): Observable<AggregatorGeographyNodeModel[]> {
    const url = `${this.basePath}/geography/node/${nodeId}/nearby-locations`;

    const params = new HttpParams().set(
      'presentation_category_id',
      presentationCategoryId
    );

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorGeographyNodeModel.fromJSON(rec))
        )
      );
  }

  getLocationPresentationCategories(
    locationId?: number
  ): Observable<AggregatorLocationPresentationCategoryModel[]> {
    const url = `${this.basePath}/_/location-presentation-categories`;

    let params = new HttpParams();

    if (locationId) {
      params = params.set('location_id', locationId);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) =>
            AggregatorLocationPresentationCategoryModel.fromJSON(rec)
          )
        )
      );
  }

  countLocationPresentationCategories(
    data: AggregatorLocationPresentationCategoryCountReqPayload
  ): Observable<AggregatorLocationPresentationCategoryCountResPayload> {
    const url = `${this.basePath}/_/location-presentation-categories/count`;

    const body: any = {
      location_id: data.locationId,
      main_category_id: data.mainCategoryId
    };

    if (data.secondaryCategoryId) {
      body.secondary_category_id = data.secondaryCategoryId;
    }

    return this.http
      .post<AggregatorLocationPresentationCategoryCountResPayload>(url, body)
      .pipe(
        map((json) => ({
          locationId: json.location_id ? json.location_id : null,
          presentationCategoryId: json.presentation_category_id
            ? json.presentation_category_id
            : null,
          count: json.count
        }))
      );
  }

  getOnlinePhotos(accountId: number): Observable<Array<{ photo_id: string }>> {
    const url = `${this.basePath}/photos/online`;

    const params = new HttpParams().set('account_id', accountId);

    return this.http.get<Array<{ photo_id: string }>>(url, { params });
  }

  getOnlinePhotoById(photoId: number): Observable<{ is_online: boolean }> {
    const url = `${this.basePath}/photo/online/${photoId}`;

    return this.http.get<{ is_online: boolean }>(url, {});
  }

  getPresentationCategories(): Observable<
    AggregatorPresentationCategoryModel[]
  > {
    const url = `${this.basePath}/_/presentation-categories`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorPresentationCategoryModel.fromJSON(rec))
        )
      );
  }

  getAggregatorSites(): Observable<AggregatorSiteModel[]> {
    const url = `${this.basePath}/_/sites`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) => json.map((rec) => AggregatorSiteModel.fromJSON(rec)))
      );
  }

  getAggregatorTrendingMeta(): Observable<AggregatorTrendingMetaModel[]> {
    const url = `${this.basePath}/_/trending-meta`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorTrendingMetaModel.fromJSON(rec))
        )
      );
  }

  getAggregatorV2Sites(): Observable<AggregatorV2SiteModel[]> {
    const url = `${this.basePath}/v2/_/sites`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) => json.map((rec) => AggregatorV2SiteModel.fromJSON(rec)))
      );
  }

  getProfileList(
    data: AggregatorProfileReqPayload
  ): Observable<AggregatorProfileModel[]> {
    const url = `${this.basePath}/profiles`;

    let params = new HttpParams().set('location_id', data.locationId);

    if (data.presentationCategoryIds?.length) {
      params = data.presentationCategoryIds.reduce((params, id) => {
        return params.append('presentation_category_ids[]', id);
      }, params);
    }

    if (data.sort) {
      params = params.set('_sort', data.sort);
    }

    if (data.page) {
      params = params.set('page', data.page);
    }

    if (data.order) {
      params = params.set('_order', data.order);
    }

    if (data.perPage !== undefined && data.perPage !== null) {
      params = params.set('_per_page', data.perPage);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) => json.map((rec) => AggregatorProfileModel.fromJSON(rec)))
      );
  }

  getProfilesBacklink(): Observable<AggregatorProfileBacklinkModel[]> {
    const url = `${this.basePath}/profiles/backlink`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfileBacklinkModel.fromJSON(rec))
        )
      );
  }

  getProfilesFillFeatured(
    data: AggregatorProfileFillFeaturedReqPayload
  ): Observable<Array<{ ad_id: string }>> {
    const url = `${this.basePath}/profiles/fill-featured`;

    let params = new HttpParams()
      .set('location_id', data.locationId)
      .set('type', data.type);

    if (data.limit) {
      params = params.set('_limit', data.limit);
    }

    if (data.presentationCategoryId) {
      params = params.set(
        'presentation_category_id',
        data.presentationCategoryId
      );
    }

    if (data.excludeAdIds?.length) {
      params = data.excludeAdIds.reduce((params, id) => {
        return params.append('exclude_ad_ids[]', id);
      }, params);
    }

    return this.http.get<Array<{ ad_id: string }>>(url, { params });
  }

  getProfilesFillPaidSpot(
    data: AggregatorProfileFillPaidSpotReqPayload
  ): Observable<Array<{ ad_id: string }>> {
    const url = `${this.basePath}/profiles/fill-paid-spot`;

    let params = new HttpParams().set('_limit', data.limit);

    if (data.locationId) {
      params = params.set('location_id', data.locationId);
    }

    if (data.presentationCategoryId) {
      params = params.set(
        'presentation_category_id',
        data.presentationCategoryId
      );
    }

    if (data.excludeAdIds?.length) {
      params = data.excludeAdIds.reduce((params, id) => {
        return params.append('exclude_ad_ids[]', id);
      }, params);
    }

    return this.http.get<Array<{ ad_id: string }>>(url, { params });
  }

  getAssociatedProfiles(
    accountId: number,
    isAvailableNow?: 0 | 1
  ): Observable<IAggregatorProfileList[]> {
    const url = `${this.basePath}/profiles-associated`;

    let params = new HttpParams().set('account_id', accountId);

    if (isAvailableNow) {
      params = params.set('is_available_now', isAvailableNow);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfileListModel.fromJSON(rec))
        )
      );
  }

  bulkOnlineProfiles(
    profileIds: number[]
  ): Observable<AggregatorBulkOnlineProfileResPayload[]> {
    const url = `${this.basePath}/profiles/online/_bulk`;
    const body = {
      profile_ids: profileIds
    };

    return this.http.post<any[]>(url, body).pipe(
      map((json) =>
        json.map(
          (rec) =>
            ({
              id: rec.id,
              isOnline: rec.is_online
            } as AggregatorBulkOnlineProfileResPayload)
        )
      )
    );
  }

  getOnlineProfilesCount(
    data: AggregatorOnlineProfileCountResPayload
  ): Observable<{ count: number }> {
    const url = `${this.basePath}/profiles/online-count`;

    let params = new HttpParams().set('account_id', data.accountId);

    if (data.isVisiting) {
      params = params.set('is_visiting', data.isVisiting);
    }

    if (data.isVip) {
      params = params.set('is_vip', data.isVip);
    }

    return this.http.get<{ count: number }>(url, { params });
  }

  getOnlineProfiles(
    data?: AggregatorOnlineProfileReqPayload
  ): Observable<AggregatorOnlineProfileResPayload[]> {
    const url = `${this.basePath}/profiles/online`;

    let params = new HttpParams();

    if (data) {
      if (data.accountId) {
        params = params.set('account_id', data.accountId);
      }

      if (data.isVisiting) {
        params = params.set('is_visiting', data.isVisiting);
      }

      if (data.isVip) {
        params = params.set('is_vip', data.isVip);
      }
    }

    return this.http.get<any[]>(url, { params }).pipe(
      map((json) =>
        json.map(
          (rec) =>
            ({
              id: rec.id,
              profileId: rec.profile_id
            } as AggregatorOnlineProfileResPayload)
        )
      )
    );
  }

  getPagingProfiles(
    data: AggregatorPagingProfileReqPayload
  ): Observable<AggregatorPagingProfileResPayload> {
    const url = `${this.basePath}/profiles/paging`;

    let params = new HttpParams()
      .set('location_id', data.locationId)
      .set('profile_id', data.profileId);

    params = data.presentationCategoryIds.reduce((params, id) => {
      return params.append('presentation_category_ids[]', id);
    }, params);

    if (data.sort) {
      params = params.set('_sort', data.sort);
    }

    if (data.order) {
      params = params.set('_order', data.order);
    }

    return this.http.get<any>(url, { params }).pipe(
      map(
        (json) =>
          ({
            previousId: json.previous_id || null,
            nextId: json.next_id || null
          } as AggregatorPagingProfileResPayload)
      )
    );
  }

  getProfileBulkPhotos(
    profileIds: number[]
  ): Observable<AggregatorProfilePhotoModel[]> {
    const url = `${this.basePath}/profile/photos/_bulk`;
    const body = {
      profile_ids: profileIds
    };

    return this.http
      .post<JsonArray>(url, body)
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfilePhotoModel.fromJSON(rec))
        )
      );
  }

  getProfilePhotosById(
    profileId: number
  ): Observable<AggregatorProfilePhotoModel[]> {
    const url = `${this.basePath}/profile/${profileId}/photos`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfilePhotoModel.fromJSON(rec))
        )
      );
  }

  searchProfiles(
    data: AggregatorSearchProfileReqPayload
  ): Observable<IAggregatorProfileList[]> {
    const url = `${this.basePath}/profiles/search`;

    let params = new HttpParams();

    if (data.locationId) {
      params = params.set('location_id', data.locationId);
    }

    if (data.mainCategory) {
      params = params.set('main_category', data.mainCategory);
    }

    if (data.searchText) {
      params = params.set('search_text', data.searchText);
    }

    if (data.isAvailableNow) {
      params = params.set('is_available_now', data.isAvailableNow);
    }

    if (data.page) {
      params = params.set('_page', data.page);
    }

    if (data.perPage) {
      params = params.set('_per_page', data.perPage);
    }

    if (data.perPage) {
      params = params.set('_per_page', data.perPage);
    }

    if (data.latitude) {
      params = params.set('latitude', data.latitude);
    }

    if (data.longitude) {
      params = params.set('longitude', data.longitude);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfileListModel.fromJSON(rec))
        )
      );
  }

  getProfilesSitemap(
    data: AggregatorProfileSitemapReqPayload
  ): Observable<AggregatorProfileSitemapResPayload[]> {
    const url = `${this.basePath}/profiles/sitemap`;

    let params = new HttpParams().set('location_id', data.locationId);

    if (data.presentationCategoryId) {
      params = params.set(
        'presentation_category_id',
        data.presentationCategoryId
      );
    }

    if (data.sort) {
      params = params.set('_sort', data.sort);
    }

    if (data.page) {
      params = params.set('page', data.page);
    }

    if (data.order) {
      params = params.set('_order', data.order);
    }

    if (data.perPage) {
      params = params.set('_per_page', data.perPage);
    }

    return this.http.get<any[]>(url, { params }).pipe(
      map((json) =>
        json.map(
          (rec) =>
            ({
              id: rec.id,
              dateModified: new Date(rec.date_modified),
              mainCategories: rec.main_categories
            } as AggregatorProfileSitemapResPayload)
        )
      )
    );
  }

  getProfileTrendingList(
    data: AggregatorProfileTrendingReqPayload
  ): Observable<IAggregatorProfileList[]> {
    const url = `${this.basePath}/profiles/trending`;

    let params = new HttpParams().set('location_id', data.locationId);

    if (data.presentationCategoryId) {
      params = params.set(
        'presentation_category_id',
        data.presentationCategoryId
      );
    }

    if (data.page) {
      params = params.set('page', data.page);
    }

    if (data.perPage) {
      params = params.set('_per_page', data.perPage);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfileListModel.fromJSON(rec))
        )
      );
  }

  getProfileTrendingPaging(
    data: AggregatorProfileTrendingPagingReqPayload
  ): Observable<AggregatorProfileTrendingPagingResPayload> {
    const url = `${this.basePath}/profiles/trending/paging`;

    let params = new HttpParams()
      .set('location_id', data.locationId)
      .set('profile_id', data.profileId);

    if (data.presentationCategoryId) {
      params = params.set(
        'presentation_category_id',
        data.presentationCategoryId
      );
    }

    return this.http.get<any>(url, { params }).pipe(
      map(
        (json) =>
          ({
            previousId: json.previous_id || null,
            nextId: json.next_id || null
          } as AggregatorProfileTrendingPagingResPayload)
      )
    );
  }

  getProfileWhatsNewAllList(
    data: AggregatorProfileWhatsNewListReqPayload
  ): Observable<IAggregatorProfileList[]> {
    const url = `${this.basePath}/profiles/trending`;

    let params = new HttpParams().set('location_id', data.locationId);

    if (data.isAvailableNow) {
      params = params.set('is_available_now', data.isAvailableNow);
    }

    if (data.page) {
      params = params.set('page', data.page);
    }

    if (data.perPage) {
      params = params.set('_per_page', data.perPage);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AggregatorProfileListModel.fromJSON(rec))
        )
      );
  }

  getProfileWhatsNewDatesById(
    profileId: string
  ): Observable<AggregatorProfileWhatsNewDateResPayload> {
    const url = `${this.basePath}/profile/${profileId}/whatsnew/dates`;

    return this.http.get<any>(url, {}).pipe(
      map(
        (json) =>
          ({
            isActive: json.is_active,
            isForced: json.is_forced,
            eligible: json.eligible,
            last: json.last || null,
            next: json.next
          } as AggregatorProfileWhatsNewDateResPayload)
      )
    );
  }

  profileWhatsNewForce(
    data: AggregatorProfileWhatsNewForceResPayload
  ): Observable<{}> {
    const url = `${this.basePath}/profile/${data.profileId}/whatsnew/_force`;
    const body = {
      reason: data.reason,
      date: data.date
    };

    return this.http.post<{}>(url, body);
  }

  getProfileWhatsNewPaging(
    data: AggregatorProfileWhatsNewPagingReqPayload
  ): Observable<AggregatorProfileWhatsNewPagingResPayload> {
    const url = `${this.basePath}/profiles/trending/paging`;

    let params = new HttpParams()
      .set('location_id', data.locationId)
      .set('profile_id', data.profileId);

    if (data.isAvailableNow) {
      params = params.set('is_available_now', data.isAvailableNow);
    }

    return this.http.get<any>(url, { params }).pipe(
      map(
        (json) =>
          ({
            previousId: json.previous_id || null,
            nextId: json.next_id || null
          } as AggregatorProfileWhatsNewPagingResPayload)
      )
    );
  }
}
