export interface ExportToFileArgs {
  format: FileType;
  url: string;
  body?: any;
  filename?: string;
}

export enum FileType {
  CSV = 'csv',
  JSON = 'json'
}
