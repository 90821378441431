import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map, Observable } from 'rxjs';
import { JsonArray, JsonObject } from 'type-fest';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '@eros-api/core';
import {
  ConfigModel,
  ManageAbsenceCalendarEventModel,
  DashboardDataModel,
  AbsenceCalendarEventModel,
  AbsenceCalendarEventsListReqPayload,
  AbsenceCalendarActionModel,
  AbsenceCalendarActionCreateReqPayload,
  AbsenceCalendarActionUpdateReqPayload,
  HolidaysLeftModel
} from '@eros-api/models';

/**
 * @link https://api.eros.com.sceon.am/calendar/apidoc/
 * */
@Injectable({
  providedIn: 'root'
})
export class AbsenceCalendarService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/calendar`;
  }

  getEventById(eventId: string): Observable<AbsenceCalendarEventModel> {
    const url = `${this.basePath}/absence/${eventId}`;

    return this.http
      .get<JsonObject>(url)
      .pipe(map((json) => AbsenceCalendarEventModel.fromJSON(json)));
  }

  getEventsList(
    data: AbsenceCalendarEventsListReqPayload
  ): Observable<AbsenceCalendarEventModel[]> {
    const url = `${this.basePath}/absence`;

    let params = new HttpParams()
      .set('date_from', data.dateFrom)
      .set('date_to', data.dateTo);

    if (data.accountId) {
      params = params.set('account_id', data.accountId);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(
        map((json) =>
          json.map((rec) => AbsenceCalendarEventModel.fromJSON(rec))
        )
      );
  }

  createEvent(
    body: ManageAbsenceCalendarEventModel
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/absence/`;

    return this.http.post<{ id: string }>(url, body);
  }

  updateEvent(
    eventId: string,
    body: ManageAbsenceCalendarEventModel
  ): Observable<unknown> {
    const url = `${this.basePath}/absence/${eventId}`;

    return this.http.put<unknown>(url, body);
  }

  deleteEvent(eventId: string): Observable<{}> {
    const url = `${this.basePath}/absence/${eventId}`;

    return this.http.delete<{}>(url, {});
  }

  getActionById(actionId: string): Observable<AbsenceCalendarActionModel> {
    const url = `${this.basePath}/action/${actionId}`;

    return this.http
      .get<JsonObject>(url, {})
      .pipe(map((json) => AbsenceCalendarActionModel.fromJSON(json)));
  }

  getActionList(): Observable<AbsenceCalendarActionModel[]> {
    const url = `${this.basePath}/action`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(
        map((json) =>
          json.map((rec) => AbsenceCalendarActionModel.fromJSON(rec))
        )
      );
  }

  getDashboardData(): Observable<DashboardDataModel[]> {
    const url = `${this.basePath}/absence/bulk`;

    return this.http
      .post<JsonArray>(url, null)
      .pipe(map((json) => json.map((rec) => DashboardDataModel.fromJSON(rec))));
  }

  getCalendarConfigs(): Observable<ConfigModel> {
    const url = `${this.basePath}/config`;

    return this.http
      .get<ConfigModel>(url, {})
      .pipe(map((json) => ConfigModel.fromJSON(json)));
  }

  createAction(
    data: AbsenceCalendarActionCreateReqPayload
  ): Observable<{ id: string }> {
    const url = `${this.basePath}/action`;

    return this.http.post<{ id: string }>(url, data);
  }

  updateAction(
    actionId: string,
    data: AbsenceCalendarActionUpdateReqPayload
  ): Observable<{}> {
    const url = `${this.basePath}/action/${actionId}`;

    return this.http.put<{}>(url, data);
  }

  deleteAction(actionId: string): Observable<{}> {
    const url = `${this.basePath}/action/${actionId}`;

    return this.http.delete<{}>(url, {});
  }

  getHolidaysLeftData(): Observable<HolidaysLeftModel[]> {
    const url = `${this.basePath}/absence/show-all`;

    return this.http
      .get<JsonArray>(url, {})
      .pipe(map((json) => json.map((rec) => HolidaysLeftModel.fromJSON(rec))));
  }
}
